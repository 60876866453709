<template>
  <div class="trusteeship_detail">
    <div class="title">基础信息</div>
    <div class="base_info">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>村集体组织:</span>{{ detail.orginizationName || "暂无" }}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>服务组织:</span>{{ detail.chemsName || "暂无" }} </p>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>托管服务面积:</span>{{ detail.area || "暂无" }}亩</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>托管期限:</span>{{ detail.trusteeshipStartDate || "暂无" }}~~~{{ detail.trusteeshipEndDate || "暂无" }} </p>
          </a-card>
        </a-col>
      </a-row>
    </div>
    
    <div class="title">保底收益</div>
    <div class="base_info">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>保底收益:</span>{{ detail.keepIncome || "暂无" }} / 亩</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>总计金额:</span>{{ (detail.keepIncome* (detail.area || 1)).toFixed(2) +"元" || "暂无" }}</p>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>收取方式:季度</span></p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>收益收取时间:</span>{{detail.keepIncomeTime}}</p>
          </a-card>
        </a-col>
      </a-row>
    </div>
    
    <div class="title">二次分红</div>
    <div class="base_info">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>是否有二次分红:</span>{{detail.secondBonus==1?"是":"否"}}</p>
          </a-card>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>服务组织向村集体支付服务费:</span>{{detail.servicePrice || "暂无"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>总计服务费:</span>{{(detail.servicePrice*(detail.area || 1)).toFixed(2)+"元"|| "暂无"}}</p>
          </a-card>
        </a-col>
      </a-row>
    </div>
    
    <div class="title">服务费用</div>
    <div class="base_info">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>服务组织向平台支付服务费:</span>{{detail.platformServicePrice || "暂无"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>总计服务费:</span>{{(detail.platformServicePrice * (detail.area || 1)).toFixed(2)}}元</p>
          </a-card>
        </a-col>
      </a-row>
    </div>
    
    <div class="title">土地综合成本</div>
    <div class="land_cost">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>保底收益:</span>{{"￥"+ detail.keepIncome+"元/亩/年"|| "暂无"}}</p>
          </a-card>
        </a-col>
          <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p></p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>总计金额:</span>{{"￥"+ detail.keepIncome*12+"元"|| "暂无"}}</p>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>二次分红:</span>{{detail.secondBonus==1?"是":"否"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>分红比例:</span>{{detail.secondBonus==1?"2:3:5":"无"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>总计分红费:</span>{{"￥"+475681+"元"|| "暂无"}}</p>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>平台服务费:</span>{{"￥"+10+"元/亩/年"|| "暂无"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p></p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>总计服务费:</span>{{"￥"+12000+"元"|| "暂无"}}</p>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>粮食收入保护费:</span>{{27.64+"/亩"|| "暂无"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>种植成本保费:</span>{{21.92+"/亩"|| "暂无"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>总计保费:</span>{{"￥"+1080179.4+"元"|| "暂无"}}</p>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>贷款金融:</span>{{300000+"元"|| "暂无"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>年利率:</span>{{4.25+"%"|| "暂无"}}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="Card title" :bordered="false">
            <p><span>总计利息:</span>{{"￥"+12750+"元"|| "暂无"}}</p>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div class="title">生产经营成本</div>
      <div class="produce_cost">
        <a-row :gutter="16">
          <a-col :span="8">
            <a-card title="Card title" :bordered="false">
              <p><span class="produce_title">第一季</span></p>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-card title="Card title" :bordered="false">
              <p><span>生产成本:</span>{{754600+"元"|| "暂无"}}</p>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card title="Card title" :bordered="false">
              <p><span>其他成本:</span>{{126980+"元"|| "暂无"}}</p>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card title="Card title" :bordered="false">
              <p><span>总计金额:</span>{{881580+"元"|| "暂无"}}</p>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="16">
            <a-col :span="8">
              <a-card title="Card title" :bordered="false">
                <p><span class="produce_title">第二季</span></p>
              </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-card title="Card title" :bordered="false">
              <p><span>生产成本:</span>{{468300+"元"|| "暂无"}}</p>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card title="Card title" :bordered="false">
              <p><span>其他成本:</span>{{89750+"元"|| "暂无"}}</p>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card title="Card title" :bordered="false">
              <p><span>总计金额:</span>{{558050+"元"|| "暂无"}}</p>
            </a-card>
          </a-col>
        </a-row>
      </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/trusteeships";
export default {
  data() {
    return {
      detail: null,
      loading: false,
    }
  },
  components: {},
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.contractId = this.$route.params.id;
      Api.detail(this.$route.params.id).then((res) => {
        this.detail = res.result;
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">
.trusteeship_detail {
  .title {
    line-height: 36px;
    background: url("~@/assets/images/lx.png") no-repeat 10px center #f9f9f9;
    font-size: 16px;
    color: #008840;
    text-indent: 40px;
    margin-left: 50px;
  }
  .base_info{
    margin-left: 70px;
    p {
        line-height: 2em;
        margin-bottom:0;
        span {
          background-color:#94E3AE;
        }
      }
  }
  .land_cost{
    margin-left: 70px;
    p {
        line-height: 2em;
        margin-bottom:0;
        span {
          color:#007A2D;
        }
      }
  }
  .produce_cost{
    margin-left: 70px;
    p {
        line-height: 2em;
        margin-bottom:0;
        span {
          color:#007A2D;
        }
      }
  }
  .produce_title{
    background-color:#94E3AE;
  }
  
}

</style>
